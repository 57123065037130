import React from 'react'
import classNames from 'classnames'

import Parallax from '../components/parallax'

import Layout from '../components/layout'

const AboutPage = () => {
  const aboutClass = classNames({
    about: true,
  })
  return (
    <Layout title="About us">
      <Parallax
        componentStyle={aboutClass}
        background="about.jpg"
        head="เกี่ยวกับเรา"
        paragraph="บริษัท เอดับเบิ้ลยู ซิเคียว ซอฟต์ จำกัด
                  เปิดกิจการอย่างเป็นทางการ เมื่อวันที่ 4 มกราคม พ.ศ.2564
                  ดำเนินธุรกิจเกี่ยวกับ Software Antivirus
                  และเป็นตัวแทนจัดจำหน่ายสินค้าทางด้านไอที ทั้งฮาร์ดแวร์
                  (Hardware) และ ซอฟต์แวร์ (Software) ทุกประเภท
                  โดยมีทีมงานผู้เชี่ยวชาญคอยให้คำปรึกษา บริการติดตั้ง ซ่อมแซม
                  และแก้ไขปัญหาหลังการขายอีกด้วย"
      />
      <div
        className="section section-presentation"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="description">
                <h3 className="header-title">นโยบายการดำเนินธุรกิจ</h3>
                <p>
                  ทางบริษัทฯ หวังเป็นอย่างยิ่งว่า สินค้าและการบริการของเรา
                  จะช่วยเติมเต็มความต้องการ
                  และช่วยเพิ่มศักยภาพและความปลอดภัยทางด้านไอทีในการทำงานของท่านลูกค้าผู้มีอุปการคุณได้เป็นอย่างดี
                  นโยบายหลักของบริษัทฯ คือ พนักงานทุกๆคน
                  ต้องยึดมั่นที่จะทำงานร่วมกันเป็นทีม
                  เพื่อการสร้างประสบการณ์ที่ดีแก่ลูกค้าด้วยความจริงใจและปรับปรุงกระบวนการธุรกิจให้ยอดเยี่ยม
                  มีความชำนาญและเชี่ยวชาญ ให้การตอบสนองที่รวดเร็ว
                  รักษาคำมั่นสัญญา พัฒนาตนเองอย่างต่อเนื่อง และสร้างความพึงพอใจ
                  หากพบว่าลูกค้าไม่ได้รับความพึงพอใจในด้านใดบริษัทจะนำมาปรับปรุงแก้ไข
                  เพื่อให้ท่านลูกค้าได้รับความคุ้มค่าและสิ่งที่ดีที่สุดเสมอ
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="description">
                <h3 className="header-title">ลักษณะผลิตภัณฑ์และบริการ</h3>
                <p>
                  ลักษณะผลิตภัณฑ์และบริการ
                  ปัจจุบันบริษัทดำเนินธุรกิจเกี่ยวกับไอที
                  เป็นตัวแทนจัดจำหน่ายสินค้าทางด้านไอที ทั้งฮาร์ดแวร์ (Hardware)
                  และ ซอฟต์แวร์ (Software) ให้กับบุคคลทั่วไป
                  องค์กรขนาดเล็กไปจนถึงขนาดใหญ่ ผลิตภัณฑ์ของเราได้แก่ Antivirus,
                  Firewall, Computer, PC, Notebook, Tablet, Printer, Software,
                  กล้องวงจรปิด CCTV และอื่นๆ ที่จะเกิดขึ้นในอนาคต
                  ตามความต้องการของตลาด โดยผลิตภัณฑ์ส่วนใหญ่ทางบริษัทฯ
                  ได้รับการแต่งตั้งให้เป็นตัวแทนจำหน่ายอย่างเป็นทางการของผลิตภัณฑ์นั้นๆ
                  บริษัทฯยังได้ทำสัญญาดูแลระบบให้บริการแบบรายเดือน/รายปี
                  (Maintenance Agreement) ให้กับหลายองค์กรอีกด้วย
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
